<template>
    <el-dialog
        :before-close="popupClose"
        :close-on-click-modal="closeOnClickOrEsc"
        :close-on-press-escape="closeOnClickOrEsc"
        :title="$t(reportTitle)"
        :visible.sync="visible"
        class="report-export"
        width="40%"
        v-on:open="popupOpened"
    >
        <div v-loading="loading" class="report-export-content">
            <div v-show="noEligibleDataFlag && reportFinished">
                <span>{{ $t("NOTIFICATION_EXPORT_FLAGS_FAILED") }}</span>
            </div>
            <div v-show="!success && reportFinished && !noEligibleDataFlag">
                <span>{{ $t("REPORT_ERROR_MSG") }}</span>
            </div>
            <div v-show="success && reportFinished">
                <p>
                    {{ $t("DOCUMENT_LINK") }}:&nbsp;
                    <a
                        id="shared-document-link"
                        class="download-link"
                        :href="downloadUrl"
                        target="_blank"
                    >
                        {{ $t("DOWNLOAD") }}
                    </a>
                </p>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import axios from "axios";
import { validationMixin } from "vuelidate";
import { dashboardApiClient } from "@/helpers/evaluation/apiclients";
import { chartExportService } from "@/services/Evaluation";
import util from "@/helpers/evaluation/util";

export default {
    name: "ReportExport",
    mixins: [util, validationMixin],
    props: ["visible", "data"],
    data() {
        return {
            loading: false,
            success: false,
            reportFinished: false,
            noEligibleDataFlag: false,
            downloadUrl: "",
            closeOnClickOrEsc: false,
            cancelTokenSource: null,
        };
    },
    computed: {
        reportTitle() {
            if (!this.reportFinished) {
                return "REPORT_GENERATING_TITLE";
            }
            if (this.success && !this.noEligibleDataFlag) {
                return "REPORT_SUCCESS_TITLE";
            }
            return "REPORT_ERROR_TITLE";
        },
    },
    methods: {
        popupOpened() {
            this.loading = true;
            this.cancelTokenSource = axios.CancelToken.source();
            if (this.data.reportType === "PDF") {
                this.pdfExport();
            } else if (this.data.reportType === "EXCEL") {
                this.excelExport();
            } else if (this.data.reportType === "ZIP") {
                this.generateZip();
            } else {
                this.popupClose();
            }
        },
        popupClose() {
            if (!this.reportFinished && this.cancelTokenSource) {
                this.cancelTokenSource.cancel();
            }
            this.$emit("reportExportClose");
            setTimeout(this.resetData, 200);
        },
        pdfExport() {
            chartExportService.getChartsBase64Data(
                this.getCharts(),
                (chartsData) => {
                    dashboardApiClient
                        .post(
                            `/dashboard-views/${this.data.dashboardViewId}/export-pdf`,
                            chartsData,
                            {
                                cancelToken: this.cancelTokenSource.token,
                            }
                        )
                        .then((response) => {
                            this.handleExportResponse(response.data);
                        })
                        .catch((error) => {
                            this.handleExportError(error);
                        });
                },
                this.$i18n
            );
        },
        excelExport() {
            const locale = this.$i18n.locale ? this.$i18n.locale : "nl";
            dashboardApiClient
                .post(
                    `/dashboard-views/${this.data.dashboardViewId}/report/lang/${locale}`,
                    this.data.name,
                    {
                        headers: {
                            "Content-Type": "text/plain",
                        },
                        cancelToken: this.cancelTokenSource.token,
                    }
                )
                .then((response) => {
                    this.handleExportResponse(response.data);
                })
                .catch((error) => {
                    this.handleExportError(error);
                });
        },
        generateZip() {
            dashboardApiClient
                .post("/generate-zip", this.data, {
                    cancelToken: this.cancelTokenSource.token,
                })
                .then((response) => {
                    this.handleExportResponse(response.data);
                })
                .catch((error) => {
                    this.handleExportError(error);
                });
        },
        handleExportResponse(data) {
            this.loading = false;
            this.success = false;
            this.noEligibleDataFlag = true;

            // Only if we receive the flag signaling the user requested a PDF export for a dashboard with only table widgets
            // do we leave the appropriate error flags and display a special message,
            // otherwise we signal success and let the user have the url for the download from S3
            if (!(data.downloadUrl === "noEligibleDataFlag")) {
                this.success = true;
                this.noEligibleDataFlag = false;
                this.downloadUrl = data.downloadUrl;
            }

            this.reportFinished = true;
            this.closeOnClickOrEsc = true;
        },
        handleExportError() {
            this.loading = false;
            this.reportFinished = true;
            this.closeOnClickOrEsc = true;
        },
        resetData() {
            this.loading = false;
            this.success = false;
            this.reportFinished = false;
            this.noEligibleDataFlag = false;
            this.downloadUrl = "";
            this.closeOnClickOrEsc = false;
        },
        getCharts() {
            // we do this here and not in parent because in case of many widgets we would see some delay in popup opening
            // so we first open popup and then try to find charts
            const charts = {};

            const widgetRefs = this.findAllWidgetRefs();
            $.each(widgetRefs, (widgetId, widgetRef) => {
                if (
                    widgetRef.$refs.widgetView &&
                    widgetRef.$refs.widgetView.$refs.widgetChart &&
                    widgetRef.$refs.widgetView.$refs.widgetChart.$refs.chart &&
                    widgetRef.$refs.widgetView.$refs.widgetChart.$refs.chart.chart
                ) {
                    charts[widgetId] =
                        widgetRef.$refs.widgetView.$refs.widgetChart.$refs.chart.chart;
                }
            });

            return charts;
        },
        findAllWidgetRefs() {
            const widgetRefs = {};
            $.each(this.$parent.$refs, (key, ref) => {
                if (ref.length > 0 && key.indexOf("row-") === 0) {
                    this.findAllWidgetRefsInRow(ref[0], widgetRefs);
                }
            });
            return widgetRefs;
        },
        findAllWidgetRefsInRow(rowRef, widgetRefs) {
            $.each(rowRef.$refs, (rowChildKey, rowChildRef) => {
                if (rowChildRef.length > 0 && rowChildKey.indexOf("column-") === 0) {
                    const columnRef = rowChildRef[0];
                    $.each(columnRef.$refs, (columnChildKey, columnChildRef) => {
                        if (columnChildRef.length > 0 && columnChildKey.indexOf("widget-") === 0) {
                            // eslint-disable-next-line prefer-destructuring
                            widgetRefs[columnChildKey.substr(7)] = columnChildRef[0];
                        } else if (
                            columnChildRef.length > 0 &&
                            columnChildKey.indexOf("row-") === 0
                        ) {
                            this.findAllWidgetRefsInRow(columnChildRef[0], widgetRefs);
                        }
                    });
                }
            });
        },
    },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"widget-view"},[(!_vm.errorMessage && _vm.widgetData != null)?_c('div',[(_vm.showGraph && _vm.widgetData.widgetType === 'SINGLE_VALUE')?_c('div',{staticClass:"singleValue"},[_c('span',{staticClass:"singleValueData",style:({ color: _vm.singleValueColor() })},[_vm._v(_vm._s(_vm.formatSingleValue()))])]):_vm._e(),(
                !_vm.chartErrorMessage &&
                _vm.chartOptions != null &&
                _vm.showGraph &&
                _vm.widgetData.widgetType !== 'SINGLE_VALUE' &&
                _vm.widgetData.widgetType !== 'TABLE'
            )?_c('widget-chart',{ref:"widgetChart",attrs:{"chart-options":_vm.chartOptions}}):_vm._e(),(!_vm.showGraph || _vm.widgetData.widgetType === 'TABLE')?_c('widget-table',{attrs:{"data":_vm.tableData,"preview":_vm.preview,"widget-id":_vm.widgetData.id,"xAxisName":_vm.xAxisName}}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTarget),expression:"showTarget"}],staticClass:"showTarget"},[_c('span',[_vm._v(_vm._s(_vm.$t("TARGET")))]),_vm._v(" - "),_c('span',[_vm._v(_vm._s(_vm.widgetData.target))])])],1):_vm._e(),(_vm.errorMessage)?_c('div',{staticClass:"widget-chart-error-message"},[_vm._v(_vm._s(_vm.$t(_vm.errorMessage)))]):_vm._e(),(
            _vm.chartErrorMessage &&
            _vm.showGraph &&
            _vm.widgetData.widgetType !== 'SINGLE_VALUE' &&
            _vm.widgetData.widgetType !== 'TABLE'
        )?_c('div',{staticClass:"widget-chart-error-message"},[_vm._v(" "+_vm._s(_vm.$t(_vm.chartErrorMessage))+" ")]):_vm._e(),_c('div',{staticClass:"clearfix"})])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <el-dialog
        :visible.sync="visible"
        v-on:opened="popupOpened"
        :before-close="popupClose"
        class="custom-dialog confirm-dialog"
        :show-close="false"
    >
        <div class="c-modal c-modal--with-tabs">
            <div class="c-modal__head">
                <div class="c-modal__title">
                    <h1>{{ $t(title) }}</h1>
                </div>
                <div class="c-modal__actions">
                    <button class="c-btn c-btn--clean c-btn--gray" @click="popupClose">
                        <i class="material-icons">close</i>
                    </button>
                </div>
            </div>
            <div class="c-modal__content">
                <div class="o-form">
                    <fieldset>
                        <ul class="o-form__fields o-list-plain">
                            <li
                                class="o-form__item o-form__item--sm o-form__item--stacked"
                                v-show="!validationStep"
                            >
                                {{ $t(message, messageParams) }}
                            </li>
                            <li
                                class="o-form__item o-form__item--sm o-form__item--stacked"
                                v-show="validationStep"
                            >
                                <input
                                    class="o-form__input o-form__input--full"
                                    type="text"
                                    ref="inputText"
                                    v-model="inputText"
                                    autocomplete="off"
                                />
                                <label class="o-form__label confirm-input-label">{{
                                    $t(inputMessage)
                                }}</label>
                            </li>
                        </ul>
                    </fieldset>
                </div>
            </div>
            <div class="c-modal__footer">
                <button class="c-btn c-btn--outline" @click="popupClose">
                    {{ $t(cancelBtn) }}
                </button>
                <button
                    class="c-btn c-btn--primary"
                    @click="dialogConfirmed"
                    :disabled="validationStep && !isTextValid()"
                >
                    {{ $t(confirmBtn) }}
                </button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "ConfirmDialog",
    props: [
        "visible",
        "title",
        "message",
        "messageParams",
        "inputMessage",
        "cancelBtn",
        "confirmBtn",
        "validationText",
        "callback",
    ],
    data() {
        return {
            validationStep: false,
            inputText: "",
        };
    },
    methods: {
        popupOpened() {
            this.validationStep = false;
            this.inputText = "";
        },
        popupClose() {
            this.$emit("close");
            this.validationStep = false;
            this.inputText = "";
        },
        dialogConfirmed() {
            if (this.validationText == null || this.validationText === "" || this.isTextValid()) {
                this.$emit("confirmed");
            } else if (!this.validationStep) {
                this.validationStep = true;
                this.$refs.inputText.focus();
            }
        },
        isTextValid() {
            return this.translate(this.validationText) === this.inputText;
        },
    },
};
</script>

<style scoped>
.confirm-input-label {
    font-size: 14px;
    text-transform: none;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"c-actionbar o-contain-padding"},[_c('nav',{staticClass:"c-breadcrumbs o-nav",attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"c-breadcrumbs__list o-nav__list"},_vm._l((_vm.navItems),function(navItem,index){return _c('li',{key:index,class:_vm.navItemClass(index)},[(typeof navItem === 'string')?_c('span',[_vm._v(_vm._s(_vm.$t(navItem)))]):_vm._e(),(typeof navItem === 'object' && navItem.type === 'label')?_c('span',{class:navItem.className},[_vm._v(_vm._s(_vm.$t(navItem.value)))]):_vm._e(),(typeof navItem === 'object' && navItem.type === 'input')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(navItem.value),expression:"navItem.value"}],class:navItem.className,domProps:{"value":(navItem.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(navItem, "value", $event.target.value)}}}):_vm._e()])}),0)]),_c('div',{staticClass:"c-actionbar__actions"},[_vm._l((_vm.actions),function(action,i){return [_c('div',{key:i},[_c('el-tooltip',{attrs:{"openDelay":500,"content":_vm.$t(action.tooltip),"disabled":!action.tooltip || _vm.isMobile,"effect":"dark","placement":"bottom"}},[(action.type === 'drawer-toggle')?_c('button',{class:[
                                'c-btn',
                                'c-btn--clean',
                                'c-btn--gray',
                                action.className,
                                action.isActive ? 'is-active' : '' ],on:{"click":action.handler}},[_c('i',{class:['material-icons', action.className]},[_vm._v(_vm._s(action.icon))])]):_vm._e()]),_c('el-tooltip',{directives:[{name:"show",rawName:"v-show",value:(action.icon !== 'more_horiz'),expression:"action.icon !== 'more_horiz'"}],attrs:{"openDelay":500,"content":_vm.$t(action.tooltip),"disabled":!action.tooltip || _vm.isMobile,"effect":"dark","placement":"bottom"}},[(action.type === 'dropdown')?_c('div',{staticClass:"c-dropdown"},[_c('button',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeDropdown),expression:"closeDropdown"}],ref:action.ref,refInFor:true,staticClass:"c-btn c-btn--clean c-btn--gray",on:{"click":_vm.toggleDropdown}},[_c('i',{staticClass:"material-icons"},[_vm._v(_vm._s(action.icon))])]),_c('div',{class:[
                                    'c-dropdown__container',
                                    'c-dropdown__container--right',
                                    'c-dropdown__container--arrow',
                                    action.cssClass ? action.cssClass : '' ]},[_c('span',{staticClass:"c-dropdown__arrow"},[_c('span'),_c('span')]),_c('ul',{staticClass:"c-dropdown__list o-list-plain"},[_vm._l((action.items),function(item,key){return [_c('li',{directives:[{name:"show",rawName:"v-show",value:(item.label !== 'SCHEDULE'),expression:"item.label !== 'SCHEDULE'"}],key:key},[(!item.icon)?_c('button',{staticClass:"c-btn c-btn--block",on:{"click":item.handler}},[_vm._v(" "+_vm._s(_vm.$t(item.label))+" ")]):_vm._e(),(item.icon)?_c('button',{staticClass:"c-btn c-btn--block c-btn--icon",on:{"click":item.handler}},[_c('i',{staticClass:"material-icons"},[_vm._v(_vm._s(item.icon))]),_vm._v(_vm._s(_vm.$t(item.label))+" ")]):_vm._e()])]})],2)])]):_vm._e()])],1)]})],2)]),_c('main',{staticClass:"o-main o-contain o-contain-padding",attrs:{"role":"main"}},[_c('Dashboard',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],key:_vm.$route.fullPath,on:{"loadDashboard":_vm.setActiveDashboard}})],1),_c('dashboard-view-add',{attrs:{"visible":_vm.dashboardViewAddVisible,"dashboard":_vm.myDashboard},on:{"close":_vm.closeDashboardViewAdd,"added":_vm.dashboardViewAdded}}),_c('confirm-dialog',{attrs:{"visible":_vm.confirmDialogVisible,"title":"DASHBOARD_APP_MODAL_DELETE_DASHBOARD_TITLE","message":"DASHBOARD_APP_MODAL_DELETE_DASHBOARD_MESSAGE","messageParams":{
            itemTitle: _vm.dashboardViewToDelete != null ? _vm.dashboardViewToDelete.title : '',
        },"inputMessage":"MODAL_DELETE_CONFIRM_MSG","cancelBtn":"CANCEL","confirmBtn":"DELETE","validationText":"MODAL_DELETE_CONFIRM"},on:{"close":_vm.closeConfirmDialog,"confirmed":_vm.dashboardViewDeletionConfirmed}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
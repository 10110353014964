<template>
    <el-col
        :xs="getColumnBreakpointSpan('xs')"
        :sm="getColumnBreakpointSpan('sm')"
        :md="getColumnBreakpointSpan('md')"
        :lg="getColumnBreakpointSpan('lg')"
        :xl="getColumnBreakpointSpan('xl')"
        :key="columnIndex"
        :class="['column', column.widgets.length === 0 ? 'empty' : '']"
        @click.native="addWidgetToColumn"
    >
        <draggable
            v-if="(!column.rows || column.rows.length === 0) && column.widgets"
            v-model="column.widgets"
            :options="dragOptions"
            class="draggable-container"
            @start="dragStarted"
            @end="dragEnded"
            @add="configurationChanged"
            @update="configurationChanged"
        >
            <template v-for="widget in column.widgets">
                <text-widget
                    v-if="widget.type === 'textWidget'"
                    :key="'text-widget-' + widget.config.widgetId"
                    :widget-id="widget.config.widgetId"
                    :edit-mode="editMode"
                    :editable="activeDashboardView.editable"
                    :is-expandable-left="isColumnExpandable(-1)"
                    :is-expandable-right="isColumnExpandable(1)"
                    @expand="expandColumn"
                    :show-expandable-box="editAllowed && row.columns.length > 1"
                    :can-be-removed="editAllowed"
                    @removed="removeWidgetFromColumn($event, 'textWidget')"
                    :ref="'text-widget-' + widget.config.widgetId"
                ></text-widget>
                <widget
                    v-else
                    :key="'widget-' + widget.config.widgetId"
                    :config="widget.config"
                    :edit-mode="editMode"
                    :editable="activeDashboardView.editable"
                    :is-expandable-left="isColumnExpandable(-1)"
                    :is-expandable-right="isColumnExpandable(1)"
                    @expand="expandColumn"
                    :show-expandable-box="editAllowed && row.columns.length > 1"
                    :can-be-removed="editAllowed"
                    @removed="removeWidgetFromColumn($event, 'unicornDefaultWidget')"
                    :widget-col-class="column.styleClass"
                    :chart-class="getChartWidthClass()"
                    :ref="'widget-' + widget.config.widgetId"
                ></widget>
            </template>
        </draggable>

        <div v-for="(columnRow, columnRowIndex) in column.rows" :key="columnRowIndex">
            <dashboard-row
                v-if="
                    column.rows &&
                    column.rows.length > 0 &&
                    (editMode || rowHasNestedRows(columnRow) || rowHasWidgets(columnRow))
                "
                :ref="'row-' + columnRowIndex"
                :row="columnRow"
                :row-index="columnRowIndex"
                :total-row-count="column.rows.length"
                :parent-column="column"
            />
        </div>
    </el-col>
</template>

<script>
import draggable from "vuedraggable";
import { validationMixin } from "vuelidate";
import { EventBus } from "@/helpers/evaluation/event-bus";
import util from "@/helpers/evaluation/util";
import DashboardRow from "@/components/Evaluation/Dashboards/DashboardRow.vue";
import Widget from "./widget/Widget.vue";
import TextWidget from "./widget/TextWidget.vue";

export default {
    name: "DashboardColumn",
    mixins: [util, validationMixin],
    props: ["row", "rowIndex", "column", "columnIndex", "editAllowed"],
    components: {
        Widget,
        TextWidget,
        draggable,
        DashboardRow,
    },
    computed: {
        activeDashboardView() {
            return this.$store.state.DashboardStore.activeDashboardView;
        },
        editMode() {
            return this.$store.state.DashboardStore.dashboardEditMode;
        },
        dragOptions() {
            return {
                group: "widgets",
                handle: ".draggable-widget",
                ghostClass: "placeholder",
                animation: 150,
                scrollSensitivity: 85,
                scrollSpeed: 15,
                disabled: !(this.editMode && this.editAllowed),
            };
        },
        addToColumnAllowed() {
            return (
                this.editMode &&
                this.editAllowed &&
                typeof this.column.rows === "undefined" &&
                this.column.widgets.length === 0
            );
        },
    },
    beforeCreate() {
        // eslint-disable-next-line global-require
        this.$options.components.DashboardRow = require("./DashboardRow.vue").default;
    },
    methods: {
        getElColSpan() {
            let width = 12;
            if (this.column.styleClass) {
                width = Number(this.column.styleClass.slice(7));
            }
            return width * 2; // multiply by 2 because element-ui layout is 24-column based
        },
        getColumnBreakpointSpan(breakpoint) {
            let colSpan = this.getElColSpan();

            if (breakpoint === "xs" || breakpoint === "sm") {
                colSpan = 24;
            } else if (breakpoint === "md") {
                colSpan += 4;
            }
            if (colSpan > 24) {
                colSpan = 24;
            }
            return colSpan;
        },
        getChartWidthClass() {
            let chartClass = "c-chart--full";
            if (this.row.columns.length > 1) {
                const columnWidth = this.getColumnWidth(this.row.columns, this.columnIndex);
                if (columnWidth < 6) {
                    chartClass = "c-chart--1of3";
                } else if (columnWidth > 6) {
                    chartClass = "c-chart--2of3";
                } else {
                    chartClass = "c-chart--1of2";
                }
            }
            return chartClass;
        },
        isColumnExpandable(direction) {
            if (!this.editAllowed) {
                return false;
            }

            const { columns } = this.row;
            if (
                (columns.length === this.columnIndex + 1 && direction > 0) ||
                (this.columnIndex === 0 && direction < 0)
            ) {
                return false;
            }
            for (
                let i = this.columnIndex + direction;
                i < columns.length && i > -1;
                i += direction
            ) {
                if (this.getColumnWidth(columns, i) > 3) {
                    return true;
                }
            }
            return false;
        },
        expandColumn(direction) {
            EventBus.$emit("expandColumn", {
                rowIndex: this.rowIndex,
                columnIndex: this.columnIndex,
                direction,
            });
        },
        addWidgetToColumn() {
            if (this.addToColumnAllowed) {
                EventBus.$emit("openWidgetTypeSelection", {
                    rowIndex: this.rowIndex,
                    columnIndex: this.columnIndex,
                });
            }
        },
        removeWidgetFromColumn(widgetId, type) {
            EventBus.$emit("removeWidgetFromColumn", {
                widgetId,
                type,
                rowIndex: this.rowIndex,
                columnIndex: this.columnIndex,
            });
        },
        dragStarted() {
            // fix 'scroll up when dragging
            window.$(".c-actionbar").style["pointer-events"] = "none";
            window.$(".c-banner").style["pointer-events"] = "none";
        },
        dragEnded() {
            window.$(".c-actionbar").style["pointer-events"] = "auto";
            window.$(".c-banner").style["pointer-events"] = "auto";
        },
        configurationChanged() {
            EventBus.$emit("configurationChanged");
        },
    },
};
</script>

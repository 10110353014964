<template>
    <el-dialog
        :before-close="popupClose"
        :show-close="false"
        :visible.sync="visible"
        class="custom-dialog fullscreen-dialog widget-popup"
        v-on:open="popupOpened"
    >
        <div
            v-if="widget != null"
            :class="['c-modal', 'c-modal--fullscreen', loading ? 'is-loading' : '']"
        >
            <div class="c-modal__head">
                <div class="c-modal__title">
                    <h1>{{ widget.title }}</h1>
                </div>
                <div class="c-modal__actions">
                    <div v-if="showGraphTableSwitcher" class="c-toggle">
                        <input
                            id="widget-popup-chart-btn"
                            v-model="showGraph"
                            :value="true"
                            class="c-toggle__item c-toggle__item--left"
                            type="radio"
                        />
                        <label for="widget-popup-chart-btn" @click="showGraph = true"
                            ><i class="material-icons">bar_chart</i></label
                        >
                        <input
                            id="widget-popup-table-btn"
                            v-model="showGraph"
                            :value="false"
                            class="c-toggle__item c-toggle__item--right"
                            type="radio"
                        />
                        <label for="widget-popup-table-btn" @click="showGraph = false"
                            ><i class="material-icons">table_chart</i></label
                        >
                    </div>
                    <div v-if="debugInfos != null && debugInfos.length > 0" class="c-toggle">
                        <template v-for="(tab, index) in tabs">
                            <div v-if="index <= debugInfos.length" :key="index">
                                <input
                                    :id="'widget-popup-tab-' + tab.id"
                                    v-model="selectedTab"
                                    :class="[
                                        'c-toggle__item',
                                        index === 0 ? 'c-toggle__item--left' : '',
                                        index > 0 && index === debugInfos.length
                                            ? 'c-toggle__item--right'
                                            : '',
                                    ]"
                                    :value="tab.id"
                                    type="radio"
                                />
                                <label :for="'widget-popup-tab-' + tab.id"
                                    ><i class="material-icons">{{ tab.icon }}</i></label
                                >
                            </div>
                        </template>
                    </div>
                    <button
                        v-if="fullScreen"
                        :title="$t('RELOAD')"
                        class="c-btn c-btn--clean c-btn--gray"
                        @click="reloadWidget"
                    >
                        <i class="material-icons">refresh</i>
                    </button>
                    <button class="c-btn c-btn--clean c-btn--gray" @click="popupClose">
                        <i class="material-icons">close</i>
                    </button>
                </div>
            </div>
            <div class="c-modal__content">
                <div v-if="selectedTab === 'preview'" class="c-chart">
                    <div class="c-chart__media">
                        <widget-view
                            :chart-error-message="chartErrorMessage"
                            :chart-options="chartOptions"
                            :error-message="errorMessage"
                            :preview="preview"
                            :show-graph="showGraph"
                            :table-data="tableData"
                            :widget-data="widgetData"
                            :x-axis-name="xAxisName"
                        ></widget-view>
                    </div>
                </div>
                <div v-for="(debugInfo, index) in debugInfos" :key="index">
                    <debug-info
                        v-if="selectedTab === 'debug' + (index + 1)"
                        :data="debugInfo"
                        :order="index + 1"
                    >
                    </debug-info>
                </div>
                <div v-show="loading" class="c-loader"></div>
            </div>
            <div class="c-modal__footer"></div>
        </div>
    </el-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { chartService, splitByService, widgetService } from "@/services/Evaluation";
import util from "@/helpers/evaluation/util";
import WidgetView from "./WidgetView.vue";
import DebugInfo from "./DebugInfo.vue";

export default {
    name: "WidgetPopup",
    mixins: [util, validationMixin],
    props: ["visible", "widget", "preview", "fullScreen"],
    components: {
        WidgetView,
        DebugInfo,
    },
    data() {
        return {
            loading: false,

            widgetData: null,
            chartOptions: null,
            errorMessage: null,
            chartErrorMessage: null,

            tableData: null,
            xAxisName: null,
            debugInfos: [],

            showGraph: true,
            selectedTab: "preview",
            tabs: [
                {
                    id: "preview",
                    selected: true,
                    icon: "visibility",
                    label: "PREVIEW",
                },
                {
                    id: "debug1",
                    selected: false,
                    icon: "bug_report",
                    label: "DEBUG1",
                },
                {
                    id: "debug2",
                    selected: false,
                    icon: "bug_report",
                    label: "DEBUG2",
                },
                {
                    id: "debug3",
                    selected: false,
                    icon: "bug_report",
                    label: "DEBUG3",
                },
            ],
        };
    },
    computed: {
        showGraphTableSwitcher() {
            return (
                this.selectedTab === "preview" &&
                this.widgetData != null &&
                this.widgetData.widgetType != null &&
                this.widgetData.widgetType !== "TABLE" &&
                this.widgetData.widgetType !== "SINGLE_VALUE" &&
                this.widgetData.widgetType !== "GAUGE" &&
                this.widgetData.widgetType !== "MOOD_MONITOR_GAUGE"
            );
        },
    },
    methods: {
        popupOpened() {
            this.getWidgetDataSet();
        },
        popupClose() {
            this.$emit("close");
            this.resetWidgetData();
        },
        getWidgetDataSet() {
            this.loading = true;
            let widgetId = null;
            let widgetTemplate = null;
            if (!this.preview) {
                widgetId = this.widget.id;
            } else {
                // eslint-disable-next-line prefer-destructuring
                widgetTemplate = this.widget.widgetTemplate;
            }
            widgetService.getWidgetDataSet(widgetId, widgetTemplate).then((result) => {
                this.loading = false;
                this.showWidget = result.exists;
                this.errorMessage = result.errorMessage;
                if (result.hasData) {
                    const widgetData = chartService.getWidgetData(
                        this.$i18n,
                        this.widget,
                        result.dataSet,
                        this.preview,
                        this.fullScreen,
                        "col-md-12"
                    );
                    this.chartErrorMessage = widgetData.error;
                    this.widgetData = widgetData.widget;
                    this.chartOptions = widgetData.chartConfig;
                    this.tableData = widgetData.tableData;
                    this.xAxisName = widgetData.widget.xAxisName;
                    if (this.fullScreen && !this.chartErrorMessage) {
                        splitByService.storeProperties();
                    }
                }
                this.debugInfos = result.dataSet.debugInfo;
            });
        },
        reloadWidget() {
            this.getWidgetDataSet();
        },
        resetWidgetData() {
            this.loading = false;
            this.widgetData = null;
            this.chartOptions = null;
            this.errorMessage = null;
            this.chartErrorMessage = null;
            this.tableData = null;
            this.xAxisName = null;
            this.debugInfos = [];
            this.showGraph = true;
            this.selectedTab = "preview";
        },
    },
};
</script>

<template>
    <div class="dashboard-row-container clearfix">
        <el-dropdown
            v-if="editMode && !parentColumn"
            class="dashboard-row-menu"
            placement="bottom-start"
            trigger="click"
            @command="handleRowMenuItemClick"
        >
            <span class="material-icons dropdown-button">list</span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                    v-show="editAllowed && (row.columns.length < 3 || hasEmptyColumn(row.columns))"
                    :command="{ action: 'addWidget', rowIndex: rowIndex }"
                >
                    {{ $t("ADD_WIDGET") }}
                </el-dropdown-item>
                <el-dropdown-item
                    v-show="editAllowed && (row.columns.length < 3 || hasEmptyColumn(row.columns))"
                    :command="{ action: 'addTextWidget', rowIndex: rowIndex }"
                >
                    {{ $t("ADD_TEXT_WIDGET") }}
                </el-dropdown-item>
                <el-dropdown-item :command="{ action: 'addRow', rowIndex: rowIndex }">
                    {{ $t("ADD_ROW_ABOVE") }}
                </el-dropdown-item>
                <el-dropdown-item :command="{ action: 'addRow', rowIndex: rowIndex + 1 }">
                    {{ $t("ADD_ROW_BELOW") }}
                </el-dropdown-item>
                <el-dropdown-item
                    v-show="rowIndex > 0"
                    :command="{ action: 'moveRow', rowIndex: rowIndex, offset: -1 }"
                    >{{ $t("MOVE_ROW_UP") }}
                </el-dropdown-item>
                <el-dropdown-item
                    v-show="rowIndex < totalRowCount - 1"
                    :command="{ action: 'moveRow', rowIndex: rowIndex, offset: 1 }"
                    >{{ $t("MOVE_ROW_DOWN") }}
                </el-dropdown-item>
                <el-dropdown-item
                    v-show="totalRowCount > 1"
                    :command="{ action: 'removeRow', rowIndex: rowIndex }"
                    >{{ $t("REMOVE_ROW") }}
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
        <el-row :gutter="32" class="dashboard-row">
            <dashboard-column
                v-for="(column, columnIndex) in row.columns"
                :key="columnIndex"
                :ref="'column-' + columnIndex"
                :column="column"
                :column-index="columnIndex"
                :edit-allowed="editAllowed"
                :row="row"
                :row-index="rowIndex"
            />
        </el-row>
    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { EventBus } from "@/helpers/evaluation/event-bus";
import util from "@/helpers/evaluation/util";
import DashboardColumn from "./DashboardColumn.vue";

export default {
    name: "DashboardRow",
    mixins: [util, validationMixin],
    props: ["row", "rowIndex", "totalRowCount", "parentColumn", "editAllowed"],
    components: {
        DashboardColumn,
    },
    computed: {
        editMode() {
            return this.$store.state.DashboardStore.dashboardEditMode;
        },
    },
    methods: {
        handleRowMenuItemClick(command) {
            if (command.action === "addWidget") {
                EventBus.$emit("addWidgetToColumn", { rowIndex: command.rowIndex });
            } else if (command.action === "addTextWidget") {
                EventBus.$emit("addTextWidgetToColumn", { rowIndex: command.rowIndex });
            } else if (command.action === "addRow") {
                EventBus.$emit("addRow", command.rowIndex);
            } else if (command.action === "moveRow") {
                EventBus.$emit("moveRow", {
                    rowIndex: command.rowIndex,
                    offset: command.offset,
                });
            } else if (command.action === "removeRow") {
                EventBus.$emit("removeRow", command.rowIndex);
            }
        },
    },
};
</script>

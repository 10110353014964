<template>
    <div class="c-drawer">
        <div class="c-drawer__container">
            <div class="c-drawer__content c-drawer__content--split-by">
                <div class="o-form">
                    <legend>{{ $t("SPLIT_BY") }}</legend>
                    <ol class="o-form__list o-list-plain">
                        <li class="o-form__item o-form__item--sm">
                            <div class="o-form__select o-form__select--full">
                                <select v-model="filterTemplate" @change="templateSelected">
                                    <option :value="null" disabled>
                                        {{ filterTemplatesLabel }}
                                    </option>
                                    <option
                                        v-for="(template, index) in filterTemplates"
                                        :key="index"
                                        v-show="template.values && template.values.length > 0"
                                        :value="template"
                                    >
                                        {{ template.name }}
                                    </option>
                                </select>
                            </div>
                        </li>
                        <li class="o-form__item o-form__item--sm">
                            <div v-click-outside="closeFilterValueDropdown" class="c-dropdown">
                                <div
                                    class="o-form__select o-form__select--full o-form__select--dropdown"
                                >
                                    <button
                                        ref="filterValueDropdown"
                                        :disabled="filterTemplate == null"
                                        class="c-btn c-btn--block"
                                        @click="toggleDropdown"
                                    >
                                        <div class="dropdown-btn-label">
                                            {{ selectedValuesLabel }}
                                        </div>
                                    </button>
                                    <div
                                        class="c-dropdown__container c-dropdown__container--full c-dropdown__container--search"
                                    >
                                        <div
                                            class="c-dropdown__content c-dropdown__content--search"
                                        >
                                            <div class="c-search">
                                                <input
                                                    v-model="searchText"
                                                    :placeholder="$t('SEARCH_FILTER')"
                                                    class="c-search__input"
                                                    type="text"
                                                /><i class="material-icons">search</i>
                                            </div>
                                            <ul class="c-dropdown__group">
                                                <li>
                                                    <div class="c-checkbox">
                                                        <input
                                                            :id="
                                                                'widget-' +
                                                                widget.id +
                                                                '-split-by-select-all'
                                                            "
                                                            v-model="allSelected"
                                                            type="checkbox"
                                                            @change="toggleAll"
                                                        />
                                                        <label
                                                            :for="
                                                                'widget-' +
                                                                widget.id +
                                                                '-split-by-select-all'
                                                            "
                                                            class="o-form__label o-form__label--checkbox"
                                                            >{{ $t("SELECT_ALL") }}</label
                                                        >
                                                    </div>
                                                </li>
                                            </ul>
                                            <ul class="c-dropdown__group">
                                                <li
                                                    v-for="(
                                                        templateValue, index
                                                    ) in filteredTemplateValues"
                                                    :key="index"
                                                >
                                                    <div class="c-checkbox">
                                                        <input
                                                            :id="
                                                                'widget-' +
                                                                widget.id +
                                                                '-split-by-value-' +
                                                                templateValue.index +
                                                                '-' +
                                                                templateValue.value
                                                            "
                                                            v-model="templateValue.selected"
                                                            :value="templateValue"
                                                            type="checkbox"
                                                        />
                                                        <label
                                                            :for="
                                                                'widget-' +
                                                                widget.id +
                                                                '-split-by-value-' +
                                                                templateValue.index +
                                                                '-' +
                                                                templateValue.value
                                                            "
                                                            class="o-form__label o-form__label--checkbox"
                                                            >{{ templateValue.value }}</label
                                                        >
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            v-if="role === 'ROLE_ADMIN' || role === 'ROLE_SUPERADMIN'"
                            class="o-form__item"
                        >
                            <div class="c-checkbox">
                                <input
                                    :id="'widget-' + widget.id + '-split-by-irremovable'"
                                    v-model="irremovable"
                                    type="checkbox"
                                />
                                <label
                                    :for="'widget-' + widget.id + '-split-by-irremovable'"
                                    class="o-form__label o-form__label--checkbox"
                                    >{{ $t("IRREMOVABLE") }}</label
                                >
                            </div>
                        </li>
                        <li class="o-form__item o-form__item--sm">
                            <button
                                :disabled="!this.splitBy.id"
                                class="c-btn c-btn--outline c-btn--block"
                                @click="removeSplitBy"
                            >
                                {{ $t("CLEAR") }}
                            </button>
                        </li>
                        <li class="o-form__item o-form__item--sm">
                            <button
                                :disabled="selectedValues.length === 0"
                                class="c-btn c-btn--primary c-btn--block"
                                @click="createSplitBy"
                            >
                                {{ $t("APPLY") }}
                            </button>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { splitByService, anonymizationService } from "@/services/Evaluation";
import util from "@/helpers/evaluation/util";

export default {
    name: "SplitBy",
    mixins: [util, validationMixin],
    props: ["visible", "widget"],
    data() {
        return {
            filterTemplate: null,
            templateValues: [],
            searchText: "",
            allSelected: false,
            irremovable: false,
        };
    },
    computed: {
        role() {
            return "ROLE_USER";
        },
        splitBy() {
            return this.widget.splitBys.length > 0 ? this.widget.splitBys[0] : {};
        },
        activeDashboard() {
            return this.$store.state.DashboardStore.activeDashboard;
        },
        activeDashboardView() {
            return this.$store.state.DashboardStore.activeDashboardView;
        },
        filterTemplates() {
            return this.$store.state.DashboardStore.filterTemplates.filter(
                (filterTemplate) =>
                    // Special case: communications dashboard and recipients table
                    // eslint-disable-next-line implicit-arrow-linebreak
                    !(
                        (this.activeDashboard.communicationsDashboard &&
                            filterTemplate.table === "dimRecipient") ||
                        !(
                            this.activeDashboardView.allowedAllFilterTemplates ||
                            (this.activeDashboardView.allowedFilterTemplateIds &&
                                this.activeDashboardView.allowedFilterTemplateIds.includes(
                                    filterTemplate.id
                                ))
                        )
                    )
            );
        },
        filterTemplatesLoaded() {
            return this.$store.state.DashboardStore.filterTemplatesLoaded;
        },
        filterTemplatesLabel() {
            if (this.filterTemplatesLoaded) {
                return this.translate("CHOOSE_FILTER");
            }
            return this.translate("DASHBOARD_APP_LOADING_FILTER_TEMPLATES");
        },
        filteredTemplateValues() {
            return this.templateValues.filter((templateValue) => {
                if (this.searchText == null || this.searchText.trim() === "") {
                    return true;
                }
                return (
                    templateValue.value
                        .toString()
                        .toLowerCase()
                        .indexOf(this.searchText.toLowerCase()) > -1
                );
            });
        },
        selectedValues() {
            const selectedValues = [];
            $.each(this.templateValues, (index, templateValue) => {
                if (templateValue.selected) {
                    selectedValues.push({ value: templateValue.value });
                }
            });
            return selectedValues;
        },
        selectedValuesLabel() {
            const numOfSelectedValues = this.selectedValues.length;
            let messageParam = numOfSelectedValues;
            if (numOfSelectedValues === 1) {
                return this.selectedValues[0].value;
            }
            if (numOfSelectedValues === 0) {
                messageParam = this.translate("NONE");
            } else if (numOfSelectedValues === this.templateValues.length) {
                messageParam = this.translate("ALL");
            }
            return this.translate("DASHBOARD_APP_NUM_OF_FILTER_VALUES_SELECTED", {
                num: messageParam,
            });
        },
    },
    mounted() {
        if (this.filterTemplatesLoaded) {
            this.init();
        }
    },
    methods: {
        init() {
            if (this.splitBy.id) {
                $.each(this.filterTemplates, (index, template) => {
                    if (template.id === this.splitBy.filterTemplate.id) {
                        this.filterTemplate = template;
                        return false;
                    }
                });

                const listOfSelectedValues = [];
                $.each(this.splitBy.splitByValues, (index, splitByValue) => {
                    listOfSelectedValues.push(splitByValue.value);
                });

                this.allSelected = this.splitBy.allValuesChecked;
                this.irremovable = this.splitBy.irremovable;
                this.populateTemplateValues(listOfSelectedValues);
            } else {
                this.resetForm();
            }
        },
        templateSelected() {
            this.allSelected = false;
            if (this.filterTemplate.table === "dimDate") {
                this.allSelected = true;
            }
            this.populateTemplateValues([]);
            this.searchText = "";
        },
        populateTemplateValues(listOfSelectedValues) {
            const values = [];
            if (this.filterTemplate != null) {
                $.each(this.filterTemplate.values, (index, value) => {
                    values.push({
                        index,
                        value: value[0],
                        selected: this.allSelected || listOfSelectedValues.indexOf(value[0]) > -1,
                    });
                });
                values.sort(this.objectSortFunc("value"));
            }
            this.templateValues = values;
        },
        toggleAll() {
            $.each(this.templateValues, (index, templateValue) => {
                templateValue.selected = this.allSelected;
            });
        },
        removeSplitBy() {
            if (this.splitBy.id) {
                splitByService.delete(this.widget.id, this.splitBy.id).then(() => {
                    if (this.widget.anonymization?.id) {
                        anonymizationService
                            .delete(this.widget.id, this.widget.anonymization.id)
                            .then(() => {
                                this.$emit("reloadWidget");
                            });
                    } else {
                        this.$emit("reloadWidget");
                    }
                });
            }
        },
        createSplitBy() {
            if (this.selectedValues.length > 0) {
                const newSplitBy = {};
                if (this.splitBy.id) {
                    newSplitBy.id = this.splitBy.id;
                }
                newSplitBy.filterTemplate = this.filterTemplate;
                newSplitBy.irremovable = this.irremovable;
                newSplitBy.allValuesChecked =
                    this.allSelected || this.templateValues.length === this.selectedValues.length;

                // Add split by values only when some of the options are selected.
                // If all options are selected we don't need to persist them because
                // those values are never retrieved.
                if (!newSplitBy.allValuesChecked) {
                    newSplitBy.splitByValues = this.selectedValues;
                }

                splitByService.save(this.widget.id, newSplitBy).then(() => {
                    this.$emit("close");
                    this.$emit("reloadWidget");
                });
            }
        },
        resetForm() {
            this.filterTemplate = null;
            this.templateValues = [];
            this.searchText = "";
            this.allSelected = false;
            this.irremovable = false;
        },
        closeFilterValueDropdown(event) {
            if (this.$refs.filterValueDropdown) {
                this.closeDropdown(event, this.$refs.filterValueDropdown);
            }
        },
    },
    watch: {
        widget() {
            if (!this.splitBy.id) {
                this.resetForm();
            }
        },
        filterTemplatesLoaded(loaded) {
            if (loaded) {
                this.init();
            }
        },
        visible() {
            if (this.visible) {
                this.init();
            }
        },
        selectedValues() {
            if (
                this.selectedValues.length > 0 &&
                this.selectedValues.length === this.templateValues.length
            ) {
                this.allSelected = true;
            } else {
                this.allSelected = false;
            }
        },
    },
};
</script>

<template>
    <div v-loading="loading">
        <div v-if="activeDashboard && activeDashboardView && splitByPropertiesLoaded">
            <FilterComponent
                v-show="showFilter"
                v-click-outside="outsideFilterClick"
                :visible="showFilter"
                :dashboard-view-id="activeDashboardView.id"
                :applied-filters="appliedFilters"
                :show-date-filter="true"
                @reload="reloadDashboard"
                @deleteFilters="openDeleteFiltersPopup"
                ref="filter"
                :displayFilterApplyButton="true"
            ></FilterComponent>
            <AppliedFilters
                v-show="appliedFilters.length > 0"
                :applied-filters="appliedFilters"
                :edit-mode="editMode"
                @reload="reloadDashboard"
            ></AppliedFilters>
            <div :class="['dashboard-container', editMode ? 'edit-mode' : '']">
                <div
                    v-for="(row, rowIndex) in activeDashboardView.configuration.rows"
                    :key="rowIndex"
                >
                    <DashboardRow
                        v-if="editMode || rowHasNestedRows(row) || rowHasWidgets(row)"
                        :ref="'row-' + rowIndex"
                        :row="row"
                        :row-index="rowIndex"
                        :total-row-count="activeDashboardView.configuration.rows.length"
                        :edit-allowed="!rowHasNestedRows(row)"
                        :class="{ 'with-nested-rows': rowHasNestedRows(row) }"
                    />
                </div>
            </div>
        </div>

        <WidgetPopup
            :visible="widgetFullScreenVisible"
            :widget="widgetForFullScreen"
            @close="closeWidgetPopup"
            :preview="false"
            :fullScreen="true"
        ></WidgetPopup>

        <WidgetSelection
            :visible="widgetSelectionVisible"
            @widgetSelectionClose="closeWidgetSelectionPopup"
            @widgetSelected="addWidget"
        ></WidgetSelection>

        <WidgetEdit
            :visible="widgetEditVisible"
            :widget-data="widgetDataForEdit"
            @updateWidgetData="updateWidgetData"
            @widgetEditClose="closeWidgetEdit"
        ></WidgetEdit>

        <TextWidget
            :visible="textWidgetPopupVisible"
            :widget-data="textWidgetDataForEdit"
            @close="closeTextWidgetPopup"
            @add="addTextWidget"
            @update="updateTextWidget"
        ></TextWidget>

        <ReportExport
            :visible="reportExportVisible"
            :data="reportExportData"
            @reportExportClose="closeReportExport"
        ></ReportExport>

        <DashboardLibraryAdd
            :visible="dashboardLibraryAddVisible"
            @dashboardLibraryAddClose="closeDashboardLibraryAdd"
        ></DashboardLibraryAdd>

        <FilterTemplateManager
            :visible="filterTemplateManagerVisible"
            @close="closeFilterTemplateManager"
            @renamed="reloadDashboard"
            @deleted="reloadDashboard"
        ></FilterTemplateManager>

        <DeleteFilters
            :visible="deleteFiltersVisible"
            :dashboard-view-id="deleteFiltersDashboardViewId"
            :widget-id="deleteFiltersWidgetId"
            :num-of-filters="deleteFiltersCount"
            @close="closeDeleteFiltersPopup"
            @deleted="filtersDeleted"
        ></DeleteFilters>

        <ElDialog
            :title="$t('DASHBOARD_APP_SELECT_WIDGET_TYPE_TITLE')"
            :visible.sync="widgetTypeSelectionDialogVisible"
            :before-close="closeWidgetTypeSelectionDialog"
            :center="true"
            class="widget-type-selection-dialog"
        >
            <span slot="footer" class="dialog-footer">
                <ElButton type="primary" @click="widgetTypeSelected('widget')">{{
                    $t("WIDGET")
                }}</ElButton>
                <ElButton type="primary" @click="widgetTypeSelected('textWidget')">{{
                    $t("TEXT_WIDGET")
                }}</ElButton>
            </span>
        </ElDialog>
    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { widgetService } from "@/services/Evaluation";
import { dashboardApiClient } from "@/helpers/evaluation/apiclients";
import { EventBus } from "@/helpers/evaluation/event-bus";
import DashboardRow from "@/components/Evaluation/Dashboards/DashboardRow.vue";
import Widget from "@/components/Evaluation/Dashboards/widget/Widget.vue";
import WidgetPopup from "@/components/Evaluation/Dashboards/widget/WidgetPopup.vue";
import WidgetSelection from "@/components/Evaluation/Dashboards/WidgetSelection.vue";
import WidgetEdit from "@/components/Evaluation/Dashboards/widget/action/WidgetEdit.vue";
import TextWidget from "@/components/Evaluation/Dashboards/widget/action/TextWidgetEdit.vue";
import ReportExport from "@/components/Evaluation/Dashboards/ReportExport.vue";
import DashboardLibraryAdd from "@/components/Evaluation/Dashboards/DashboardLibraryAdd.vue";
import AppliedFilters from "@/components/Evaluation/Dashboards/widget/AppliedFilters.vue";
import FilterComponent from "@/components/Evaluation/Dashboards/widget/action/Filter.vue";
import FilterTemplateManager from "@/components/Evaluation/Dashboards/FilterTemplateManager.vue";
import DeleteFilters from "@/components/Evaluation/Dashboards/DeleteFilters.vue";
import util from "@/helpers/evaluation/util";

export default {
    name: "Dashboards",
    mixins: [util, validationMixin],
    components: {
        DashboardRow,
        // eslint-disable-next-line vue/no-unused-components
        Widget,
        WidgetPopup,
        WidgetSelection,
        WidgetEdit,
        TextWidget,
        ReportExport,
        DashboardLibraryAdd,
        AppliedFilters,
        FilterComponent,
        FilterTemplateManager,
        DeleteFilters,
    },
    data() {
        return {
            loading: false,

            appliedFilters: [],
            showFilter: false,

            widgetFullScreenVisible: false,
            widgetForFullScreen: null,

            widgetSelectionVisible: false,
            selectedRowIndex: null,
            selectedColumnIndex: null,

            widgetEditVisible: false,
            widgetDataForEdit: null,

            textWidgetPopupVisible: false,
            textWidgetDataForEdit: null,

            reportExportVisible: false,
            reportExportData: null,

            dashboardLibraryAddVisible: false,

            filterTemplateManagerVisible: false,

            deleteFiltersVisible: false,
            deleteFiltersDashboardViewId: null,
            deleteFiltersWidgetId: null,
            deleteFiltersCount: 0,

            widgetTypeSelectionDialogVisible: false,
            widgetTypeSelectionData: null,
        };
    },
    computed: {
        role() {
            return "ROLE_USER";
        },
        navItems() {
            return this.$store.state.DashboardStore.navItems;
        },
        dashboards() {
            return this.$store.state.DashboardStore.dashboards;
        },
        activeDashboard() {
            return this.$store.state.DashboardStore.activeDashboard;
        },
        activeDashboardView() {
            return this.$store.state.DashboardStore.activeDashboardView;
        },
        splitByPropertiesLoaded() {
            return this.$store.state.DashboardStore.splitByPropertiesLoaded;
        },
        editMode() {
            return this.$store.state.DashboardStore.dashboardEditMode;
        },
        actions() {
            if (this.activeDashboardView == null) {
                return [];
            }

            const actions = [];

            if (this.editMode && (this.role === "ROLE_ADMIN" || this.role === "ROLE_SUPERADMIN")) {
                actions.push({
                    type: "button",
                    btnType: "primary",
                    label: "SAVE_CHANGES",
                    cssClass: "save-dashboard-btn",
                    handler: this.saveDashboard,
                });
            }

            actions.push({
                type: "drawer-toggle",
                icon: "filter_list",
                className: "dashboard-filter-toggle",
                tooltip: "FILTER",
                isActive: this.showFilter,
                handler: this.toggleFilter,
            });

            actions.push({
                type: "dropdown",
                icon: "cloud_download",
                ref: "dashboard_export_dropdown",
                cssClass: "export-dropdown",
                tooltip: "EXPORT",
                items: [
                    { label: "EXPORT_TO_PDF", handler: this.exportToPdf },
                    { label: "EXPORT_TO_EXCEL", handler: this.exportToExcel },
                    { label: "SCHEDULE", handler: this.scheduleReports },
                ],
            });

            const dashboardActionItems = [];
            if (!this.editMode && (this.role === "ROLE_ADMIN" || this.role === "ROLE_SUPERADMIN")) {
                dashboardActionItems.push({
                    label: "EDIT_DASHBOARD",
                    icon: "edit",
                    handler: this.editDashboard,
                });
                dashboardActionItems.push({
                    label: "SAVE_TO_DASHBOARD_LIBRARY",
                    icon: "library_add",
                    handler: this.saveToDashboardLibrary,
                });
                dashboardActionItems.push({
                    label: "DASHBOARD_APP_MANAGE_FILTER_TEMPLATES_BTN",
                    icon: "filter_list",
                    handler: this.openFilterTemplateManager,
                });
            }

            if (dashboardActionItems.length > 0) {
                actions.push({
                    type: "dropdown",
                    icon: "more_horiz",
                    ref: "dashboard_action_dropdown",
                    cssClass: "c-dropdown__container--lg",
                    items: dashboardActionItems,
                });
            }

            return actions;
        },
        dragOptions() {
            return {
                group: "widgets",
                handle: ".widget",
                ghostClass: "placeholder",
                animation: 150,
                scrollSensitivity: 85,
                scrollSpeed: 15,
                disabled: !this.editMode,
            };
        },
    },
    methods: {
        getFilters(dashboardViewId) {
            dashboardApiClient
                .get(`/dashboard-views/${dashboardViewId}/filters`)
                .then((response) => {
                    this.appliedFilters = response.data;
                })
                .catch(() => {
                    this.displayMessage(
                        this.translate("DASHBOARD_APP_ERROR_GETTING_DASHBOARD_FILTERS"),
                        "error"
                    );
                });
        },
        openWidgetPopup(widget) {
            this.widgetForFullScreen = widget;
            this.widgetFullScreenVisible = true;
        },
        closeWidgetPopup() {
            this.widgetFullScreenVisible = false;
            this.widgetForFullScreen = null;
        },
        openWidgetSelectionPopup(rowIndex, columnIndex) {
            this.selectedRowIndex = rowIndex;
            this.selectedColumnIndex = columnIndex;
            this.widgetSelectionVisible = true;
        },
        closeWidgetSelectionPopup() {
            this.widgetSelectionVisible = false;
        },
        openTextWidgetEdit(widgetData) {
            this.openTextWidgetPopup(widgetData, null, null);
        },
        openTextWidgetPopup(widgetData, rowIndex, columnIndex) {
            this.selectedRowIndex = rowIndex;
            this.selectedColumnIndex = columnIndex;
            this.textWidgetDataForEdit = widgetData;
            this.textWidgetPopupVisible = true;
        },
        closeTextWidgetPopup() {
            this.textWidgetPopupVisible = false;
            this.textWidgetDataForEdit = null;
        },
        openWidgetEdit(widgetData) {
            this.widgetDataForEdit = widgetData;
            this.widgetEditVisible = true;
        },
        closeWidgetEdit() {
            this.widgetEditVisible = false;
            this.widgetDataForEdit = null;
        },
        outsideFilterClick(event) {
            if (
                this.activeDashboardView != null &&
                !event.target.classList.contains("dashboard-filter-toggle") &&
                !this.$refs.filter?.$refs.valueFilter.$refs.filterValueDropdown.classList.contains(
                    "is-active"
                )
            ) {
                this.showFilter = false;
                this.$store.commit("DashboardStore/setActionBarItems", this.actions);
            }
        },
        editDashboard() {
            if (this.activeDashboardView != null) {
                this.$store.commit("DashboardStore/setDashboardEditMode", true);
            }
        },
        saveToDashboardLibrary() {
            this.dashboardLibraryAddVisible = true;
        },
        closeDashboardLibraryAdd() {
            this.dashboardLibraryAddVisible = false;
        },
        openFilterTemplateManager() {
            this.filterTemplateManagerVisible = true;
        },
        closeFilterTemplateManager() {
            this.filterTemplateManagerVisible = false;
        },
        openDeleteFiltersPopup(widgetData) {
            if (widgetData) {
                this.deleteFiltersDashboardViewId = null;
                this.deleteFiltersWidgetId = widgetData.widgetId;
                this.deleteFiltersCount = widgetData.filtersCount;
            } else {
                this.deleteFiltersWidgetId = null;
                this.deleteFiltersDashboardViewId = this.activeDashboardView.id;
                this.deleteFiltersCount = this.appliedFilters.length;
            }
            this.deleteFiltersVisible = true;
        },
        closeDeleteFiltersPopup() {
            this.deleteFiltersVisible = false;
            this.deleteFiltersDashboardViewId = null;
            this.deleteFiltersWidgetId = null;
            this.deleteFiltersCount = 0;
        },
        openWidgetTypeSelectionDialog(data) {
            this.widgetTypeSelectionDialogVisible = true;
            this.widgetTypeSelectionData = data;
        },
        closeWidgetTypeSelectionDialog() {
            this.widgetTypeSelectionDialogVisible = false;
            this.widgetTypeSelectionData = null;
        },
        scheduleReports() {
            this.$router.push({ name: "schedule-reports" });
        },
        addWidgetToColumn(data) {
            // NOTE: this function doesn't support nested rows
            this.openWidgetSelectionPopup(data.rowIndex, data.columnIndex);
        },
        addTextWidgetToColumn(data) {
            // NOTE: this function doesn't support nested rows
            this.openTextWidgetPopup(null, data.rowIndex, data.columnIndex);
        },
        widgetTypeSelected(type) {
            if (type === "textWidget") {
                this.addTextWidgetToColumn(this.widgetTypeSelectionData);
            } else {
                this.addWidgetToColumn(this.widgetTypeSelectionData);
            }
            this.closeWidgetTypeSelectionDialog();
        },
        addWidget(widgetTemplate) {
            if (this.activeDashboardView) {
                widgetService
                    .addWidgetToDashboard(this.activeDashboardView.id, widgetTemplate.id)
                    .then((widget) => {
                        // 'wid' was used before for Sortable, it's NOT widget id (we don't need it now so we're not setting it in widgetConfig)
                        const widgetConfig = {
                            type: "unicornDefaultWidget",
                            config: {
                                widgetId: widget.id,
                                widgetTitle: widget.title,
                                widgetTarget: widget.target,
                                skipBenchmarkFilters: false,
                            },
                        };
                        this.addNewWidgetToConfiguration(
                            widgetConfig,
                            this.selectedRowIndex,
                            this.selectedColumnIndex
                        );
                    });
            }
        },
        addTextWidget(text) {
            if (this.activeDashboardView) {
                widgetService
                    .addTextWidgetToDashboard(this.activeDashboardView.id, text)
                    .then((response) => {
                        const widgetConfig = {
                            type: "textWidget",
                            config: {
                                widgetId: response.data.id,
                            },
                        };
                        this.addNewWidgetToConfiguration(
                            widgetConfig,
                            this.selectedRowIndex,
                            this.selectedColumnIndex
                        );
                    });
            }
        },
        addNewWidgetToConfiguration(widgetConfig, rowIndex, columnIndex) {
            // NOTE: this function operates on a top level row (doesn't support nested rows)
            const configuration = this.copyObject(this.activeDashboardView.configuration);

            const { columns } = configuration.rows[rowIndex];
            if (columnIndex) {
                // add to specified column
                columns[columnIndex].widgets.push(widgetConfig);
            } else {
                // try to find empty column
                let emptyColumnIndex = null;
                columns.forEach((column, index) => {
                    if (column.widgets.length === 0) {
                        emptyColumnIndex = index;
                        return false;
                    }
                });
                if (emptyColumnIndex != null) {
                    // add to empty column
                    columns[emptyColumnIndex].widgets.push(widgetConfig);
                } else {
                    // add new column
                    const width = 12 / (columns.length + 1);
                    columns.forEach((column) => {
                        this.setColumnWidth(column, width);
                    });
                    columns.push({ styleClass: `col-md-${width}`, widgets: [widgetConfig] });
                }
            }

            this.updateConfiguration(configuration, this.triggerResize);
        },
        removeWidgetFromColumn(data) {
            // NOTE: this function operates on a top level row (doesn't support nested rows)
            const configuration = this.copyObject(this.activeDashboardView.configuration);

            // find column
            const { columns } = configuration.rows[data.rowIndex];
            const column = columns[data.columnIndex];
            if (column && column.widgets) {
                // remove widget from column
                column.widgets.forEach((widget, index) => {
                    if (widget.config.widgetId === data.widgetId && widget.type === data.type) {
                        column.widgets.splice(index, 1);
                        return false;
                    }
                });
                // check if there are still widgets in column
                if (column.widgets.length === 0) {
                    // remove empty column
                    if (configuration.rows[data.rowIndex].columns.length > 1) {
                        columns.splice(data.columnIndex, 1);

                        const width = 12 / columns.length;
                        columns.forEach((col) => {
                            this.setColumnWidth(col, width);
                        });
                    } else {
                        this.setColumnWidth(column, 12);
                    }
                }

                this.updateConfiguration(configuration, this.triggerResize);
            }
        },
        updateWidgetData(widgetData) {
            const configuration = this.copyObject(this.activeDashboardView.configuration);

            const widget = this.findWidget(
                configuration,
                widgetData.widgetId,
                widgetData.widgetType
            );
            if (widget != null) {
                widget.config.widgetTitle = widgetData.title;
                let reload = false;
                if (widget.config.widgetTarget !== widgetData.target) {
                    widget.config.widgetTarget = widgetData.target;
                    reload = true;
                }
                if (widget.config.skipBenchmarkFilters !== widgetData.skipBenchmarkFilters) {
                    // no need to reload widget if skipBenchmarkFilters was previously undefined
                    if (widget.config.skipBenchmarkFilters || widgetData.skipBenchmarkFilters) {
                        reload = true;
                    }
                    widget.config.skipBenchmarkFilters = widgetData.skipBenchmarkFilters;
                }
                this.updateConfiguration(configuration, () => {
                    if (reload) {
                        EventBus.$emit("reloadWidget", widgetData.widgetId);
                    }
                });
            }
        },
        updateTextWidget(widgetData) {
            EventBus.$emit("updateTextWidget", widgetData);
        },
        saveDashboard() {
            let configuration = null;
            if (this.activeDashboardView.editable) {
                const newTitle = this.navItems[0].value;

                if (!newTitle || newTitle.length < 4 || newTitle.length > 32) {
                    this.displayMessage(this.translate("MIN_NUMBER_OF_CHARACTERS"), "error");
                    return;
                }

                if (this.activeDashboardView.configuration.title !== newTitle) {
                    configuration = this.copyObject(this.activeDashboardView.configuration);
                    configuration.title = newTitle;
                }
            }
            this.$store.commit("DashboardStore/setDashboardEditMode", false);
            if (configuration != null) {
                this.updateConfiguration(configuration);
            } else {
                this.$store.commit("DashboardStore/setNavItems", [
                    {
                        type: "label",
                        value: this.activeDashboardView.configuration.title,
                        className: "dashboard-title-label",
                    },
                ]);
            }
            this.$store.commit("DashboardStore/setActionBarItems", this.actions);
        },
        addRow(rowIndex) {
            // NOTE: this function operates on a top level (doesn't support nested rows)
            const configuration = this.copyObject(this.activeDashboardView.configuration);
            configuration.rows.splice(rowIndex, 0, {
                columns: [{ styleClass: "col-md-12", widgets: [] }],
            });
            this.updateConfiguration(configuration);
        },
        moveRow(data) {
            // NOTE: this function operates on a top level (doesn't support nested rows)
            const configuration = this.copyObject(this.activeDashboardView.configuration);

            const targetRowIndex = data.rowIndex + data.offset;
            const tempRow = configuration.rows[data.rowIndex];
            configuration.rows[data.rowIndex] = configuration.rows[targetRowIndex];
            configuration.rows[targetRowIndex] = tempRow;

            this.updateConfiguration(configuration);
        },
        removeRow(rowIndex) {
            // NOTE: this function operates on a top level (doesn't support nested rows)
            const configuration = this.copyObject(this.activeDashboardView.configuration);

            // delete widgets
            configuration.rows[rowIndex].columns.forEach((column) => {
                column.widgets.forEach((widget) => {
                    if (widget.type === "textWidget") {
                        widgetService.deleteTextWidget(widget.config.widgetId);
                    } else {
                        widgetService.deleteWidget(widget.config.widgetId);
                    }
                });
            });

            // remove row
            configuration.rows.splice(rowIndex, 1);

            this.updateConfiguration(configuration);
        },
        updateConfiguration(configuration, callback) {
            if (this.role === "ROLE_SUPERADMIN" || this.role === "ROLE_ADMIN") {
                dashboardApiClient
                    .put(
                        `/dashboards/${this.activeDashboard.id}/dashboard-views/${this.activeDashboardView.id}`,
                        configuration
                    )
                    .then(() => {
                        this.$store.commit(
                            "DashboardStore/updateActiveDashboardView",
                            configuration
                        );
                        this.$storage.setItem("selectedView", this.activeDashboardView);
                        if (!this.editMode) {
                            this.$store.commit("DashboardStore/setNavItems", [
                                {
                                    type: "label",
                                    value: this.activeDashboardView.configuration.title,
                                    className: "dashboard-title-label",
                                },
                            ]);
                        }

                        this.$forceUpdate();

                        if (callback) {
                            callback();
                        }
                    })
                    .catch(() => {
                        this.displayMessage(
                            this.translate("DASHBOARD_APP_UPDATE_VIEW_CONFIGURATION_ERROR"),
                            "error"
                        );
                        if (!this.editMode) {
                            this.$store.commit("DashboardStore/setNavItems", [
                                {
                                    type: "label",
                                    value: this.activeDashboardView.configuration.title,
                                    className: "dashboard-title-label",
                                },
                            ]);
                        }
                    });
            }
        },
        expandColumn(data) {
            // NOTE: this function operates on a top level (doesn't support nested rows)
            const configuration = this.copyObject(this.activeDashboardView.configuration);

            const { columns } = configuration.rows[data.rowIndex];

            for (
                let i = data.columnIndex + data.direction;
                i < columns.length && i > -1;
                i += data.direction
            ) {
                if (this.getColumnWidth(columns, i) > 3) {
                    this.setColumnWidth(columns[i], this.getColumnWidth(columns, i) - 1);
                    this.setColumnWidth(
                        columns[data.columnIndex],
                        this.getColumnWidth(columns, data.columnIndex) + 1
                    );
                    break;
                }
            }

            this.updateConfiguration(configuration, this.triggerResize);
        },
        configurationChanged() {
            this.updateConfiguration(this.activeDashboardView.configuration);
        },
        triggerResize() {
            this.$nextTick(() => {
                window.dispatchEvent(new Event("resize"));
            });
        },
        exportToPdf() {
            this.openReportExport({
                reportType: "PDF",
                dashboardViewId: this.activeDashboardView.id,
            });
        },
        exportToExcel() {
            const date = new Date().toISOString().slice(0, 10).replace(/-/g, "");
            const name = `${this.activeDashboardView.configuration.title}_${date}`;
            const data = {
                reportType: "EXCEL",
                dashboardViewId: this.activeDashboardView.id,
                name: name.replace(/\s/g, "_").replace(/[/\\?%*:|"<>]/g, "_"),
            };
            this.openReportExport(data);
        },
        openReportExport(data) {
            this.reportExportData = data;
            this.reportExportVisible = true;
        },
        closeReportExport() {
            this.reportExportVisible = false;
            this.reportExportData = null;
        },
        toggleFilter() {
            this.showFilter = !this.showFilter;
            this.$store.commit("DashboardStore/setActionBarItems", this.actions);
        },
        filtersDeleted(widgetId) {
            if (widgetId) {
                EventBus.$emit("reloadWidget", widgetId);
            } else {
                this.reloadDashboard();
            }
        },
        reloadDashboard() {
            this.getFilters(this.activeDashboardView.id);
            EventBus.$emit("reloadWidget");
        },
    },
    watch: {
        activeDashboardView(newDashboardView, oldDashboardView) {
            if (oldDashboardView != null) {
                this.$store.commit("DashboardStore/setDashboardEditMode", false);
            }
            if (newDashboardView != null) {
                this.getFilters(this.activeDashboardView.id);
                this.$store.commit("DashboardStore/setNavItems", [
                    {
                        type: "label",
                        value: this.activeDashboardView.configuration.title,
                        className: "dashboard-title-label",
                    },
                ]);
            } else {
                this.appliedFilters = [];
                this.$store.commit("DashboardStore/setNavItems", []);
            }
            this.showFilter = false;
            this.$store.commit("DashboardStore/setActionBarItems", this.actions);
        },
        editMode() {
            if (this.editMode) {
                if (this.activeDashboardView.editable) {
                    this.$store.commit("DashboardStore/setNavItems", [
                        {
                            type: "input",
                            value: this.activeDashboardView.configuration.title,
                            className: "dashboard-title-input",
                        },
                    ]);
                } else {
                    this.$store.commit("DashboardStore/setNavItems", [
                        {
                            type: "label",
                            value: this.activeDashboardView.configuration.title,
                            className: "dashboard-title-label-edit",
                        },
                    ]);
                }
            } else {
                this.$store.commit("DashboardStore/setNavItems", [
                    {
                        type: "label",
                        value: this.activeDashboardView.configuration.title,
                        className: "dashboard-title-label",
                    },
                ]);
            }
            this.$store.commit("DashboardStore/setActionBarItems", this.actions);
        },
    },
    created() {
        if (this.activeDashboardView != null) {
            this.$store.commit("DashboardStore/setNavItems", [
                {
                    type: "label",
                    value: this.activeDashboardView.configuration.title,
                    className: "dashboard-title-label",
                },
            ]);
            this.getFilters(this.activeDashboardView.id);
        }
        this.$store.commit("DashboardStore/setActionBarItems", this.actions);

        EventBus.$on("openWidgetEdit", this.openWidgetEdit);
        EventBus.$on("openTextWidgetEdit", this.openTextWidgetEdit);
        EventBus.$on("openWidgetPopup", this.openWidgetPopup);
        EventBus.$on("deleteFilters", this.openDeleteFiltersPopup);
        EventBus.$on("configurationChanged", this.configurationChanged);
        EventBus.$on("expandColumn", this.expandColumn);
        EventBus.$on("openWidgetTypeSelection", this.openWidgetTypeSelectionDialog);
        EventBus.$on("addWidgetToColumn", this.addWidgetToColumn);
        EventBus.$on("addTextWidgetToColumn", this.addTextWidgetToColumn);
        EventBus.$on("removeWidgetFromColumn", this.removeWidgetFromColumn);
        EventBus.$on("addRow", this.addRow);
        EventBus.$on("moveRow", this.moveRow);
        EventBus.$on("removeRow", this.removeRow);
    },
};
</script>

<template>
    <div
        v-if="showWidget"
        :class="[
            'c-chart',
            'c-box',
            'text-widget',
            'draggable-widget',
            loading ? 'is-loading' : '',
        ]"
    >
        <div
            v-show="editMode && (showExpandableBox || isExpandableLeft || isExpandableRight)"
            class="widget-expand-menu clearfix"
        >
            <a v-show="isExpandableLeft" @click="expandWidget(-1)"
                ><span class="material-icons">arrow_back</span></a
            >
            <a v-show="isExpandableRight" class="expand_right" @click="expandWidget(1)"
                ><span class="material-icons">arrow_forward</span></a
            >
        </div>
        <div v-if="editMode" class="c-chart__header c-drawer">
            <div class="c-chart__actions">
                <el-tooltip :openDelay="500" :content="$t('EDIT')" effect="dark" placement="bottom">
                    <button class="c-btn c-btn--clean c-btn--gray" @click="editWidget">
                        <i class="material-icons">edit</i>
                    </button>
                </el-tooltip>
                <el-tooltip
                    :openDelay="500"
                    :content="$t('REMOVE')"
                    effect="dark"
                    placement="bottom"
                >
                    <button
                        v-if="canBeRemoved"
                        class="c-btn c-btn--clean c-btn--gray text-widget-remove-btn"
                        @click="removeWidget"
                    >
                        <i class="material-icons">close</i>
                    </button>
                </el-tooltip>
            </div>
        </div>
        <div class="c-chart__content">
            <div v-if="widget != null" class="text-widget-content" v-html="widget.text"></div>
        </div>
        <div v-show="loading" class="c-loader"></div>
    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { EventBus } from "@/helpers/evaluation/event-bus";
import util from "@/helpers/evaluation/util";
import { widgetService } from "@/services/Evaluation";

export default {
    name: "TextWidget",
    mixins: [util, validationMixin],
    props: [
        "widgetId",
        "editable",
        "editMode",
        "canBeRemoved",
        "isExpandableLeft",
        "isExpandableRight",
        "showExpandableBox",
    ],
    data() {
        return {
            loading: false,
            widget: null,
            showWidget: true,
        };
    },
    computed: {
        role() {
            return "ROLE_USER";
        },
    },
    created() {
        EventBus.$on("updateTextWidget", this.updateWidgetHandler);

        this.getWidget();
    },
    beforeDestroy() {
        EventBus.$off("updateTextWidget", this.updateWidgetHandler);
    },
    methods: {
        getWidget() {
            this.loading = true;

            widgetService
                .getTextWidget(this.widgetId)
                .then((response) => {
                    this.loading = false;
                    this.widget = response.data;
                })
                .catch((error) => {
                    this.loading = false;
                    if (error.response.status === 404) {
                        this.showWidget = false;
                        this.$emit("removed", this.widgetId);
                    }
                });
        },
        updateWidgetHandler(widgetData) {
            if (this.widget != null && this.widget.id === widgetData.widgetId) {
                this.widget.text = widgetData.text;
            }
        },
        removeWidget() {
            if (!this.canBeRemoved) {
                return;
            }

            this.$confirm(
                this.translate("DASHBOARD_APP_REMOVE_TEXT_WIDGET_MSG"),
                this.translate("DASHBOARD_APP_REMOVE_WIDGET_TITLE"),
                {
                    confirmButtonText: this.translate("REMOVE"),
                    cancelButtonText: this.translate("CANCEL"),
                    type: "warning",
                }
            ).then(() => {
                widgetService
                    .deleteTextWidget(this.widgetId)
                    .then(() => {
                        this.$emit("removed", this.widgetId);
                    })
                    .catch((error) => {
                        if (error.response.status === 404) {
                            this.$emit("removed", this.widgetId);
                        }
                    });
            });
        },
        expandWidget(direction) {
            this.$emit("expand", direction);
        },
        editWidget() {
            EventBus.$emit("openTextWidgetEdit", this.widget);
        },
    },
};
</script>

<template>
    <div class="c-drawer">
        <div
            :class="[
                {
                    'c-drawer__container': true,
                    'dashboard-filter': !!dashboardViewId,
                    'dashboard-filter--desktop-sub-navigation-closed': desktopSubNavigationClosed,
                },
            ]"
        >
            <div class="c-drawer__content">
                <div
                    v-show="appliedFilters.length > 0 || dashboardViewId"
                    class="c-drawer__filters"
                >
                    <applied-filters
                        :applied-filters="appliedFilters"
                        @reload="reload"
                    ></applied-filters>
                    <button class="c-btn c-btn--clean c-btn--gray" @click="deleteFilters">
                        <i class="material-icons">delete</i>
                    </button>
                </div>
                <div class="o-form">
                    <date-filter
                        v-if="showDateFilter"
                        :applied-filters="appliedFilters"
                        :dashboard-view-id="dashboardViewId"
                        @reload="reload"
                    ></date-filter>
                    <template-value-filter
                        ref="valueFilter"
                        :dashboard-view-id="dashboardViewId"
                        :visible="visible"
                        :widget-id="widgetId"
                        :displayFilterApplyButton="displayFilterApplyButton"
                        @reload="reload"
                        @applyFilter="applyFilter"
                    ></template-value-filter>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { mapState } from "vuex";
import { EventBus } from "@/helpers/evaluation/event-bus";
import util from "@/helpers/evaluation/util";
import AppliedFilters from "../AppliedFilters.vue";
import DateFilter from "./DateFilter.vue";
import TemplateValueFilter from "./TemplateValueFilter.vue";

export default {
    name: "FilterComponent",
    mixins: [util, validationMixin],
    props: [
        "visible",
        "widgetId",
        "dashboardViewId",
        "appliedFilters",
        "showDateFilter",
        "displayFilterApplyButton",
    ],
    components: {
        AppliedFilters,
        DateFilter,
        TemplateValueFilter,
    },
    computed: {
        ...mapState({
            desktopSubNavigationClosed: (state) => state.DashboardStore.desktopSubNavigationClosed,
        }),
    },
    methods: {
        reload() {
            this.$emit("reload");
        },
        applyFilter() {
            this.$emit("applyFilter");
        },
        deleteFilters() {
            if (this.dashboardViewId) {
                EventBus.$emit("deleteFilters");
            } else {
                EventBus.$emit("deleteFilters", {
                    widgetId: this.widgetId,
                    filtersCount: this.appliedFilters.length,
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~include-media";
@import "@/style_variables/style_variables.scss";

.dashboard-filter {
    top: 107px;
    padding: 0;

    @include media($isDesktop) {
        margin-left: 556px;
        width: calc(100vw - 620px);
        top: 115px;

        &.dashboard-filter--desktop-sub-navigation-closed {
            margin-left: 328px;
            width: calc(100vw - 392px);
        }
    }

    .c-drawer__content {
        border: none;
    }
}
</style>
